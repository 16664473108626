import { graphql } from "gatsby"
import styled from 'styled-components'
import PropTypes from "prop-types"
import React, { Component } from "react"

import Layout from "../layouts"
import Head from "../components/head"
// import DefaultHero from "../components/default-hero"
import ImageHero from "../components/image-hero"
import CopyRightBG from "../components/copyright-bg"
import CopyFull from "../components/copyfull"
import CTAImage from "../components/cta-image"
import FAQAccordions from '../components/faq-accordions';

import YoastSeoParts from '../fragments/seo';

class PostTemplate extends Component {
  render() {
    const solution = this.props.data.wpSolution
    const { seo } = solution
    const { image, title, copy, mainCta, cta, rightColumn, leftColumn, faqs } = solution.cptSolution;

    console.log("in single solution");
    console.log({solution});
    // @TODO: STEP #5: Use title and content in Gatsby.
    return (
      <Layout>
        <PageWrapper accentColor={'#ff6a00'}>
          <Head title={seo.title} description={seo.metaDesc} meta={seo} />
          <ImageHero image={solution.featuredImage} title={solution.title} copy={""} />
          {/* <DefaultHero title={solution.title} backgroundColor="#005C85" />  */}
          <CopyRightBG image={image} title={title} copy={copy} cta={cta}/>
          <CopyFull copy={solution.content} cta={mainCta} />
          { faqs &&
            <FAQAccordions title={faqs.name} faqs={faqs.faqs.nodes} />
          }
          <CTAImage rightColumn={rightColumn} leftColumn={leftColumn}/>
        </PageWrapper>
      </Layout>
    )
  }
}

const PageWrapper = styled.div`
  .defaultHero .dots span {
    color: ${({ accentColor }) => accentColor};
  }

  .defaultHero {
    border-top-color: ${({ accentColor }) => accentColor};
  }

  .eventBoxImage {
    border-color: ${({ accentColor }) => accentColor};
  }
`

PostTemplate.propTypes = {
  data: PropTypes.object.isRequired,
  edges: PropTypes.array,
}

export default PostTemplate

// @TODO: STEP #4: Get current WP Post data via ID.
export const pageQuery = graphql`
  query($id: String!) {
    wpSolution(id: {eq: $id}) {
      title
      content
      seo {
        ...seoParts
      }
      cptSolution {
        title
        copy
        mainCta {
          target
          title
          url
        }
        cta {
          target
          title
          url
        }
        image {
          altText
          localFile {
            publicURL
          }
        }
        faqs {
          name
          faqs {
            nodes {
              title
              link
              excerpt
            }
          }
        }
        leftColumn {
          copy
          image {
            localFile {
              publicURL
            }
          }
          cta {
            target
            title
            url
          }
        }
        rightColumn {
          copy
          image {
            localFile {
              publicURL
            }
          }
          cta {
            target
            title
            url
          }
        }
      }
      featuredImage {
        node {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`