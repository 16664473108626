/* For this to work you will need to add a div around each piece of content (not the toggler) with the class .collapsible. The toggler will get a class of .toggle-collapse. When the toggler is clicked it will grab it's immediate sibling and show it (if it's already shown it will do nothing) so make sure that the .collapsible div is always right after the toggler. */

export function toggleCollapsible() {
  const togglers = document.querySelectorAll('.toggle-collapse');

  if (togglers.length) {
    // give the first toggler the class of .open on load
    togglers[0].classList.add('open');

    // add a click listener to all the togglers
    togglers.forEach(toggler => {
      toggler.addEventListener('click', async (e) => {
        const togglerIsOpen = e.currentTarget.classList.contains('open');

        // close all the togglers
        togglers.forEach(toggler => toggler.classList.remove('open'));

        await document.querySelectorAll('.collapsible').forEach(collapsible => {
          collapsible.style.display = 'none';
        });

        if (!togglerIsOpen) {
          // add the class of .open to the clicked toggler and show content
          e.currentTarget.nextElementSibling.style.display = 'block';
          e.currentTarget.classList.add('open');
        }
      });
    });
  }
}