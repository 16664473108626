import React, { useEffect } from "react"
import { graphql } from "gatsby"

import '../css/copyfull.css';

import { toggleCollapsible } from '../utils/toggleCollapsible';

const CopyFull = ({ title, copy, cta, ctaAlt, ctaAlt2, ctaAlt3, align, titleAlignment, ctaAlignment }) => {
  useEffect(() => {
    toggleCollapsible();
  }, []);

  let containerClasses = 'container copyFull';

  if(align == 'center') {
    containerClasses += ' center-align';
  }
  return (
    <div className={containerClasses}>
      <div className="grid smallGrid">
        <div className="col-sm-12 maincopy">
          { title &&
            <div className={`title ${titleAlignment || 'left'}-align`}>
              <h2 dangerouslySetInnerHTML={{ __html: title }} />
              <hr />
            </div>
          }

          <div dangerouslySetInnerHTML={{ __html: copy }}></div>
          {(cta || ctaAlt || ctaAlt2 || ctaAlt3) && (
            <div className={`flex-group btn-group ${ctaAlignment}`}>
              { cta &&
                <a className="mainCTA" href={cta.url} target={cta.target}>{cta.title}</a>
              }
              { ctaAlt &&
                <a className="mainCTA altCTA" href={ctaAlt.url} target={ctaAlt.target}>{ctaAlt.title}</a>
              }
              { ctaAlt2 &&
                <a className="mainCTA" href={ctaAlt2.url} target={ctaAlt2.target}>{ctaAlt2.title}</a>
              }
              { ctaAlt3 &&
                <a className="mainCTA altCTA" href={ctaAlt3.url} target={ctaAlt3.target}>{ctaAlt3.title}</a>
              }
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default CopyFull